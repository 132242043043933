<template>
  <div class="test"></div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  // import {debugConsole} from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: {Portlet},
    mounted() {},
    data(){
      return {
        modalState: true
      };
    },
    methods: {}
  }
</script>

<style scoped>

</style>
